import React, { useState } from 'react';
import './Nav.css';

function Nav({isClicked, handleClick,handleAbout,handleHome,handleProjects}) {

  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank"); // Navigate to the specified URL
  };

  return (
    <nav>
      <div id="left">
        <li onClick={() => handleLinkClick("https://gitlab.com/hoomanafshari")}>GitLab</li>
        <li onClick={() => handleLinkClick("https://github.com/hoomanafshari1380")}>GitHub</li>
      </div>
      <div id="right">
        <li id="plus" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <span>{isHovered ? '×' : '≡'}</span>
          <ul class="dropdown">
          <li onClick={handleProjects}>Home 🏠︎</li>
          <li onClick={handleHome}>Projects</li>
          <li onClick={handleAbout}>About</li>
          </ul>
        </li>
        <li onClick={handleClick}> {isClicked ? 'Go back' : 'Contact'}</li>
      </div>

    </nav>

  );
}

export default Nav;
