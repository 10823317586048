import React, { useState } from 'react';
import './Welcome.css';
import { TypeAnimation } from 'react-type-animation';

function Welcome({ handleWLC }) {
  const [animationFinished, setAnimationFinished] = useState(false);
  
  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  return (
    <>
      <section>
        <div id="f2">
          <p id="top-bash">Hooman-Afshari {"{"}</p>
          <TypeAnimation
            sequence={[
              "Web & Mobile Applications", 3000,
              "SQL/NoSQL", 3000,
              "DevOps-CI/CD", 3000,
              "Full-Stack Developer", 3000,
              "Multi-Containers-Docker", 3000,
            ]}
            wrapper="span"
            speed={30}
            repeat={Infinity}
            className="typing" // Correctly apply the class name
          />
          <p id="btm-bash">{"}"}</p>
        </div>

        <div id="fl">
          <div
            id="welcome"
            style={animationFinished ? { backgroundColor: '#072150be' } : {}}
            onClick={handleWLC}
          >
            <svg viewBox="0 0 1900 550">
              <text
                x="50%"
                y="70%"
                fill={animationFinished ? '#FFFFFF' : 'transparent'}
                textAnchor="middle"
                onAnimationEnd={handleAnimationEnd}
                className={animationFinished ? 'finished hover-text' : 'hover-text'}
              >
                START ◉
              </text>
            </svg>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
