import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section id="contact-section">
      <div id="contact-info">
        <p>Email: <span>hooman8097@gmail.com</span></p>
        <p>Linkedin: <a href="https://ca.linkedin.com/in/hooman-afshari-231b1a2b0">Click</a></p>
        <p>Phone: <span>226-899-0331</span></p>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448161.8654411846!2d-73.92503877940717!3d45.50168941115439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91b3ebd0d8125%3A0x9477b9ff0989f50!2sMontreal%2C%20QC!5e0!3m2!1sen!2sca!4v1683649089455!5m2!1sen!2sca&z=14"
          width="200"
          height="50%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map of Montreal"
        ></iframe>
      </div>
    </section>
  );
}

export default Contact;
