import React from 'react';
import './About.css';
import meImage from './me.jpg';

function About() {


  return (
    <section className="about">
      <div id="about-items">
      <h1>About</h1>
      <div id="row">
        <div id="ab-left">
        <div id="HA">
            <svg id="text" width="1420" height="1701" viewBox="0 0 1420 1701" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2004_549)">
                <path d="M39.5428 484.007C165.67 491.776 349.016 482.938 326.305 297.253C318.705 235.11 297.919 55.229 282.089 115.995C254.071 223.549 252.939 356.839 245.903 466.965C230.818 703.079 251.053 936.581 268.203 1171.11C278.948 1318.03 263.92 1488.21 291.453 1632.12C308.289 1720.12 269.423 1397.77 272.752 1307.48C277.988 1165.5 234.97 1072.94 251.09 931.156C257.491 874.846 347.676 879.492 391.864 897.497C461.063 925.696 633.88 972.257 699.885 929.871C787.774 873.43 720.606 700.714 734.43 601.194C752.712 469.569 763.641 336.646 777.533 204.475C778.956 190.94 783.224 150.334 781.801 163.869C762.997 342.778 743.589 521.499 736.558 701.342C726.877 948.924 717.293 1215.54 763.811 1458.68C773.307 1508.31 774.548 1571.19 787.793 1618.45C798.549 1656.83 824.157 1566.18 824.264 1565.73C863.273 1402.61 868.692 1231.3 891.61 1065.44C923.271 836.312 976.92 610.187 1043.48 389.673C1069.59 303.167 1104.88 138.143 1164.32 69.2916C1222.3 2.13707 1187.33 251.493 1182.64 343.106C1169.02 609.696 1190.41 871.861 1228.15 1134.12C1244.34 1246.65 1271.77 1353.3 1295.47 1463.44C1300.59 1487.23 1303.6 1548.28 1323.57 1563.99C1331.75 1570.43 1336.17 1510.97 1336.88 1504.19C1349.99 1379.44 1322.25 1267.23 1308.49 1145.66C1290.71 988.698 1260.47 879.437 1105.59 835.407C1055.45 821.152 812.732 832.713 820.242 761.264" stroke="url(#paint0_linear_2004_549)" stroke-opacity="0.6" stroke-width="12" stroke-linecap="round"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_2004_549" x1="125.579" y1="173.085" x2="1607.46" y2="979.249" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ff55"/>
                <stop offset="1" stop-color="#00ffdd"/>
                </linearGradient>
                <clipPath id="clip0_2004_549">
                <rect width="1420" height="1701" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </div>
        <div>
          <p id="ab">My name is Hooman Afshari and I'm a recent graduate developer based in Montreal, Canada.
          Throughout my studies, I've had the opportunity to work on several individual and group projects. 
          I've developed a wide range of applications, including web apps, games, data visualization tools, and mobile platforms.
          I have a genuine passion for creating innovative apps that can both assist and entertain users.
          </p>
        </div>
      </div>
      <div id="ab-right">
        <img id="me" src={meImage} alt="me"></img>
      </div>
    </div>
    </div>
    </section>
  );
}

export default About;