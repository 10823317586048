import React from 'react';
import './All.css';


function CourseManager() {


  return (
    <section>
      <div id="all-items">
        <h1>Course Manager (Python App)</h1>
        <p>
        Implemented a comprehansive School Course Management System using <b>Python</b> and <b>SQL</b> <br></br>
        enabling CRUD (create, read, update, and delete) based on user input.<br></br><br></br> In order to represent the
        relationships between tables, and optimized <b>ERD</b> was created.<br></br><br></br>
        <b>PL/SQL</b> Packages are implemented to handle the back-end logic, specifically for <br></br> sending
        and reciving the data through <b>REST APIs</b> <br></br> <br></br>
        Added <b>unit tests</b> to ensure quality assurance and correct functioning of the routers. <br></br> <br></br>
        Visit the source code <a href="https://gitlab.com/hoomanafshari/coursemanagerapp-pyton"> here</a>.
        </p>
        {/* <table>
            <tr>
              <td><img className="all-images" src={game} alt="game"></img></td>
              <td><img className="all-images" src={game1} alt="game1"></img></td>
              <td><img className="all-images" src={game2} alt="game2"></img></td>
            </tr>
          </table> */}
        <div id="down">
          <div id="all-left">
          <h3>Languages & Frameworks</h3>
          <ul>
              <li>Pythoon</li>
              <li>Flask</li>
              <li>SQL</li>
            </ul>
          </div>
          <div id="all-right">
          <h3>Features</h3>
          <ul>
              <li>User login, logout, sign up, and delete their account</li>
              <li>User remove, add, adn update their courses</li>
              <li>Teachers can create groups for students in the same class</li>
              <li>Passords are hashed after sign up</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CourseManager;
