import React from 'react';
import './All.css';

import mob from "./../mob.PNG";
import mob1 from "./../mob1.PNG";

function Calendar() {


  return (
    <section>
      <div id="all-items">
        <h1>Mobile Calendar APP</h1>
        <p>
        Developed core functionalities using Kotlin, enabling users to add, 
        edit, and delete events for specific days.
        Integrated SQLite and Room for efficient storage and retrieval of 
        event data.
        Ensured an intuitive user interface and smooth navigation for an 
        enhanced user experience.
        Implemented clean architecture to enhance code maintainability and 
        separation of concerns throughout the development lifecycle.
        Incorporated real-time weather and holiday features by leveraging 
        Retrofit for weather and HttpURLConnection for holidays.
        <br></br> <br></br>
        Visit the source code <a href="https://gitlab.com/hoomanafshari/calendarandroidapp">here</a>.
        </p>
        <table>
            <tr>
              <td><img className="mob_images" src={mob} alt="mob"></img></td>
              <td><img className="mob_images" src={mob1} alt="mob1"></img></td>
            </tr>
          </table>
        <div id="down">
          <div id="all-left">
          <h3>Languages & Frameworks</h3>
          <ul>
              <li>Kotlin</li>
              <li>Android</li>
            </ul>
          </div>
          <div id="all-right">
          <h3>Features</h3>
          <ul>
              <li>User can create/edit/remove daily events</li>
              <li>User can see official holidays for each day</li>
              <li>User can see real-time weather in weekly view</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Calendar;