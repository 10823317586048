import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import './App.css';
import Welcome from "./components/Welcome";
import Nav from "./components/Nav";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import Calendar from "./components/projects/Calendar";
import Dream from "./components/projects/Dream";
import Game from "./components/projects/Game";
import Crimes from "./components/projects/Crimes";
import CourseManger from './components/projects/CourseManager';

function App() {

  useEffect(() => {
    if (isMobile) {
      //alert('Mobile view is under maintenance. Please visit on your computer for a better experience.');
    }
  },[])

  const [isClicked, setIsClicked] = useState(false);
  const [applyAnimate, setAnimate] = useState(false);
  const [current, setCurrent] = useState(null);

  var handleWLC = () => {
    setIsClicked(false);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<Home handleDream={handleDream} handleCalendar={handleCalendar}
        handleGame={handleGame} handleCrimes={handleCrimes} handleCourse={handleCourse}/>);      
      setAnimate(false); // Reset animation state
    }, );
  };
  
  var handleAbout = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<About />);
      setAnimate(false); // Reset animation state
    }, 200);
  };

  
  var handleGame = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<Game />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  var handleCrimes = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<Crimes />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  var handleDream = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<Dream />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  var handleCalendar = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<Calendar />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  var handleCourse = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<CourseManger />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  if (!current) {
    setCurrent(<Welcome handleWLC={handleWLC}/>);
  }

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  handleAbout = () => {
    setIsClicked(false);
    setAnimate(true);
    // Delay setting the new component until after the animation finishes
    setTimeout(() => {
      setCurrent(<About />);
      setAnimate(false); // Reset animation state
    }, 200); // Adjust this delay according to your transition duration
  };

  const handleHome = () => {
    setIsClicked(false);
    setAnimate(true);
    setTimeout(() => {
      setCurrent(<Home handleDream={handleDream} handleCalendar={handleCalendar} handleGame={handleGame} handleCrimes={handleCrimes} handleCourse={handleCourse} />);
      setAnimate(false);
    }, 200);
  };

  const handleProjects = () => {
    setIsClicked(false);
    setAnimate(true);
    setTimeout(() => {
      setCurrent(<Welcome handleWLC={handleWLC}/>);
      setAnimate(false);
    }, 200);
  };

  return (
    <>
     <Nav isClicked={isClicked} handleClick={handleClick} handleAbout={handleAbout} handleHome={handleHome} handleProjects={handleProjects}
      /> 
      <div className={`card ${isClicked ? 'clicked' : ''}`}>
        <div className={`transition-container ${applyAnimate ? 't' : ''}`}>{current}</div>
        <div className="contact">
          <Contact/>
        </div>
      </div>
    </>
  );
}

export default App;
