import React, { useState } from 'react';
import './Home.css';
import Game from './Game.PNG';
import dream from './dream.PNG';
import mob from './mob.PNG';
import crime from './crime.PNG';
import python from './python.png'

function Home({ handleDream, handleCalendar, handleGame, handleCrimes, handleCourse }) {
  const [selectedType, setSelectedType] = useState('All'); // Default to "All"

  const handleTypeClick = (type) => {
    setSelectedType(type); // Set the selected type
  };

  return (
    <>
      <p>Select from the bottom: </p>
      <div id="type-select">
        <p
          onClick={() => handleTypeClick('All')}
          className={selectedType === 'All' ? 'selected' : ''}
        >
          All
        </p>
        <p
          onClick={() => handleTypeClick('Web')}
          className={selectedType === 'Web' ? 'selected' : ''}
        >
          Web
        </p>
        <p
          onClick={() => handleTypeClick('Mobile')}
          className={selectedType === 'Mobile' ? 'selected' : ''}
        >
          Mobile
        </p>
        <p
          onClick={() => handleTypeClick('Cross-platform')}
          className={selectedType === 'Cross-platform' ? 'selected' : ''}
        >
          Cross-platform
        </p>
      </div>

      <section className="home">
        <div className="grid-container">
          {/* Conditionally render items based on selectedType */}
          {(selectedType === 'All' || selectedType === 'Web') && (
            <div className="grid-item" onClick={handleDream}>
              <img className="pro-images" src={dream} alt="dream" />
              <label className="pro-label">Dreamscape</label>
            </div>
          )}
          {(selectedType === 'All' || selectedType === 'Cross-platform') && (
            <div className="grid-item" onClick={handleGame}>
              <img className="pro-images" id="game-img" src={Game} alt="game" />
              <label className="pro-label">Maze Game</label>
            </div>
          )}
          {(selectedType === 'All' || selectedType === 'Web') && (
            <div className="grid-item" onClick={handleCrimes}>
              <img className="pro-images" id="crime-img" src={crime} alt="crime" />
              <label className="pro-label">LA & NYC Crimes</label>
            </div>
          )}
          {(selectedType === 'All' || selectedType === 'Mobile') && (
            <div className="grid-item" onClick={handleCalendar}>
              <img className="pro-images" src={mob} alt="mobile" />
              <label className="pro-label">Calendar App</label>
            </div>
          )}

          {(selectedType === 'All' || selectedType === 'Web') && (
            <div className="grid-item" onClick={handleCourse}>
              <img className="pro-images" id="crime-img" src={python} alt="course Manager" />
              <label className="pro-label">Course Manager</label>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Home;
